import React, {useEffect, useRef, useState} from 'react';
import notificationSlice, {NotificationType} from '../../../features/notification/notificationSlice';
import NotificationModal from '../modal/NotificationModal';
import {useDispatch} from 'react-redux';
import notificationOperations from '../../../features/notification/notificationOperations';
import {useTranslation} from 'react-i18next';
import Hammer from 'hammerjs';
import BasicModal from '../../BasicModal/BasicModal';
import useWindowSize from '../../../hooks/useWindowSize';

import circleCheck from '../../../assets/icons/notification/circle-check.svg';
import circleInformationYellow from '../../../assets/icons/notification/circle-unformation-yellow.svg';
import circleInformationBlue from '../../../assets/icons/notification/circle-information-blue.svg';
import circleError from '../../../assets/icons/notification/circle-error.svg';
import deleteBtn from '../../../assets/icons/notification/delete-btn.svg';
import deleteBtnMobile from '../../../assets/icons/notification/delete-btn-mobile.svg';
import unread from '../../../assets/icons/notification/unread.svg';

import useDateFormatting from '../../../hooks/useDateFormatting';
import DateFormat from '../../../hooks/enums/DateFormat';

import notificationStyles from './Notification.module.scss';

interface Props {
    notification: NotificationType;
    page: string | number;
}

export enum NotificationTypeEnum {
    NewAcceptance = 'NEW_ACCEPTANCE',
    ApproveAcceptance = 'APPROVE_ACCEPTANCE',
    RejectAcceptance = 'REJECT_ACCEPTANCE',
    OfferSubscription = 'OFFER_SATISFIES_SUBSCRIPTION'
}

const Notification: React.FC<Props> = ({notification}) => {

    const [showDeleteBtn, setShowDeleteBtn] = useState(false);

    const [showReadBtn, setShowReadBtn] = useState(true);

    const dispatch = useDispatch();

    const {t} = useTranslation();

    const [isNotificationModalOpen, toggleNotificationModal] = useState(false);

    const [isNotificationDeleteModalOpen, toggleNotificationDeleteModal] = useState(false);

    type ContentType = {
        header: string;
        body: string;
    }

    const selectNotificationImage = (type: string) => {
        switch (type) {
            case NotificationTypeEnum.NewAcceptance:
                return <img src={circleInformationYellow} alt="yellow" className={notificationStyles.img} />;
            case NotificationTypeEnum.ApproveAcceptance:
                return <img src={circleCheck} alt="check" className={notificationStyles.img} />;
            case NotificationTypeEnum.RejectAcceptance:
                return <img src={circleError} alt="reject" className={notificationStyles.img} />;
            case NotificationTypeEnum.OfferSubscription:
                return <img src={circleInformationBlue} alt="reject" className={notificationStyles.img} />;
            default:
                return '';
        }
    };

    const getAcceptancePrice = (context: string) => {
        const acceptancePrice: string = JSON.parse(context).acceptancePrice;
        return acceptancePrice;
    };

    const getOfferTitle = (context: string) => {
        return JSON.parse(context).offerTitle;
    };

    const formattedDate = useDateFormatting();

    const selectTypeContentNotification = (type: string) => {
        switch (type) {
            case NotificationTypeEnum.NewAcceptance:
                return {
                    header: `${t(`notification.content.${NotificationTypeEnum.NewAcceptance}.header`)} ${getAcceptancePrice(notification.notificationContext)}!`,
                    body: `${t(`notification.content.${NotificationTypeEnum.NewAcceptance}.body`)}`,
                } as ContentType;
            case NotificationTypeEnum.ApproveAcceptance:
                return {
                    header: `${t(`notification.content.${NotificationTypeEnum.ApproveAcceptance}.header`)} «${getOfferTitle(notification.notificationContext)}»!`,
                    body: `${t(`notification.content.${NotificationTypeEnum.ApproveAcceptance}.body`)}`,
                } as ContentType;
            case NotificationTypeEnum.RejectAcceptance:
                return {
                    header: `${t(`notification.content.${NotificationTypeEnum.RejectAcceptance}.header`)} «${getOfferTitle(notification.notificationContext)}»!`,
                    body: `${t(`notification.content.${NotificationTypeEnum.RejectAcceptance}.body`)}`,
                } as ContentType;
            case NotificationTypeEnum.OfferSubscription:
                return {
                    header: `${t(`notification.content.${NotificationTypeEnum.OfferSubscription}.header`)}!`,
                    body: `${t(`notification.content.${NotificationTypeEnum.OfferSubscription}.body`)}`,
                } as ContentType;
            default:
                return {} as ContentType;
        }
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const notificationRef = useRef<HTMLDivElement | null>(null);

    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (notificationRef.current && isScreenResolutionMobile) {
            const hammer = new Hammer(notificationRef.current);
            hammer.on('swipeleft', () => {
                setVisible(true);
                if (notificationRef.current) {
                    (notificationRef.current as HTMLElement).style.transform = 'translateX(-20%)';
                    const deleteButton = notificationRef.current.querySelector(notificationStyles.delete_button);
                    if (deleteButton) {
                        deleteButton.style.display = 'block';
                        deleteButton.style.transform = 'translateX(0%)';
                    }
                }
            });
        }
    }, [visible]);

    useEffect(() => {
        if (notificationRef.current && isScreenResolutionMobile) {
            const hammer = new Hammer(notificationRef.current);
            hammer.on('swiperight', () => {
                setVisible(false);
                if (notificationRef.current) {
                    notificationRef.current.style.transform = 'translateX(0)';
                    const deleteButton = notificationRef.current.querySelector(notificationStyles.delete_button);
                    if (deleteButton) {
                        deleteButton.style.display = 'none';
                    }
                }
            });
        }
    }, [visible]);

    useEffect(() => {
        setTimeout(() => {
            if (visible && notificationRef.current && isScreenResolutionMobile) {
                notificationRef.current.style.transform = 'translateX(0)';
            }
        }, 5000);
    }, [visible]);

    const deleteNotification = async () => {
        await dispatch(notificationOperations.deleteNotification(notification.id));
        await dispatch(notificationSlice.actions.removeNotification(notification.id));
        toggleNotificationDeleteModal(!isNotificationDeleteModalOpen);
    };

    return (
        <>
            <div className={notificationStyles.main_div}>
                <div
                    ref={notificationRef}
                    className={notificationStyles.notification}
                    onMouseOver={() => setShowDeleteBtn(true)}
                    onMouseLeave={() => setShowDeleteBtn(false)}
                    onClick={async () => {
                        await dispatch(notificationOperations.updateNotification({id: notification.id}));
                        await dispatch(notificationOperations.getUnreadCountNotifications());
                        setShowReadBtn(false);
                        toggleNotificationModal(!isNotificationModalOpen);
                    }}
                    key={notification.id}
                >
                    <div className={notificationStyles.line}></div>

                    <div className={notificationStyles.notification_flex_start}>

                        {selectNotificationImage(notification.notificationType)}

                        <div className={notificationStyles.w}>

                            <div className={notificationStyles.notification_flex_end}>
                                <p className={notificationStyles.name}>
                                    {selectTypeContentNotification(notification.notificationType).header}
                                </p>
                                <p className={notificationStyles.time}>{formattedDate(notification.createdOn, DateFormat.NormalAndTime)}</p>
                            </div>

                            <div className={notificationStyles.notification_flex_end}>
                            <span className={notificationStyles.text}>
                                {selectTypeContentNotification(notification.notificationType).body}
                            </span>
                                {showDeleteBtn && !isScreenResolutionMobile &&
                                  <img src={deleteBtn} alt="delete" className={notificationStyles.delete}
                                       onClick={(event) => {
                                           event.stopPropagation();
                                           toggleNotificationDeleteModal(!isNotificationDeleteModalOpen);
                                       }} />}
                                {!notification.read && !showDeleteBtn && showReadBtn &&
                                  <img src={unread} alt="unread" className={notificationStyles.unread} />}
                            </div>

                        </div>
                    </div>
                </div>
                {isScreenResolutionMobile &&
                  <button
                    className={notificationStyles.delete_button}
                    onClick={(event) => {
                        event.stopPropagation();
                        toggleNotificationDeleteModal(!isNotificationDeleteModalOpen);
                    }}>
                    <div className={notificationStyles.delete_button__flex}>
                      <img src={deleteBtnMobile} alt="deleteBtnMobile"
                           className={notificationStyles.delete_mobile_svg} />
                      <span className={notificationStyles.delete_text}>{t('notification.delete')}</span>
                    </div>
                  </button>}
            </div>

            {notification.id !== undefined &&
              <BasicModal isModalOpen={isNotificationDeleteModalOpen}
                          toggleModal={toggleNotificationDeleteModal}
                          header={t('acceptancePage.removeAcceptanceModal.header')}
                          question={t('notification.deleteModal.question')}
                          leftButton={t('notification.deleteModal.cancel')}
                          rightButton={t('notification.deleteModal.delete')}
                          onClick={deleteNotification}
              />}

            {notification &&
              <NotificationModal isModalOpen={isNotificationModalOpen} toggleModal={toggleNotificationModal}
                                 notification={notification} />}
        </>
    );
};

export default Notification;