import React from 'react';

import tooltipStyles from './Tooltip.module.scss';

interface Props {
    img: string;
    text: string;
}

const Tooltip = ({text, img}: Props) => {

    return (
        <div className={tooltipStyles.tooltip_container}>
            <img src={img} alt="Tooltip image" className={tooltipStyles.svg}/>
            <div className={tooltipStyles.tooltip}>
                {text}
            </div>
        </div>
    );
};

export default Tooltip;