import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {FieldValues, UseFormRegister} from 'react-hook-form';
import {FieldErrors} from 'react-hook-form/dist/types/errors';
import useWindowSize from '../../../hooks/useWindowSize';
import {OfferElementsType} from '../../../models/generalTypes';

import apartmentBlockStyles from './ApartmentBlockStyles.module.scss';

interface Props {
    register: UseFormRegister<FieldValues>,
    errors: FieldErrors,
    offerWithId?: OfferElementsType;
}

const ApartmentBlock = ({register, errors, offerWithId}: Props) => {

    const [checkboxes, setCheckboxes] = useState([false, false, false, false]);

    const {t} = useTranslation();

    const handleCheckboxClick = (index: number) => {
        setCheckboxes(prevState => {
            const newState = [...prevState];
            newState[index] = !newState[index];
            for (let i = 0; i < newState.length; i++) {
                if (index !== i) {
                    newState[i] = false;
                }
            }
            return newState;
        });
    };

    useEffect(() => {
        const rooms = offerWithId?.rooms;
        if (rooms !== undefined && rooms >= 0 && rooms < checkboxes.length) {
            const newCheckboxes = [...checkboxes];
            newCheckboxes[rooms - 1] = true;
            setCheckboxes(newCheckboxes);
        }
    }, []);

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div>
            <h4>{t('createNewOfferPage.apartment.title')}</h4>

            <div className="">
                <div className={isScreenResolutionMobile ? 'flex-column' : 'flex_row_new_offer jcsa'}>
                    <div
                        className={`lbl-apartment mt-3 ${apartmentBlockStyles.mr_minus}`}>{t('createNewOfferPage.apartment.room')}</div>
                    <div className="mt-1" id="rooms">
                        <label className="control" htmlFor="1">
                            <input className=""
                                   type="checkbox"
                                   id="1"
                                   value="1"
                                   onClick={() => handleCheckboxClick(0)}
                                   checked={checkboxes[0]}
                                   {...register('rooms', {
                                       valueAsNumber: true,
                                       value: '1',
                                       required: t('formErrors.required').toString(),
                                   })}
                            />
                            <span className="control__content">1</span>
                        </label>

                        <label className="control" htmlFor="2">
                            <input type="checkbox"
                                   id="2"
                                   value="2"
                                   onClick={() => handleCheckboxClick(1)}
                                   checked={checkboxes[1]}
                                   {...register('rooms', {
                                       valueAsNumber: true,
                                       value: '2',
                                       required: t('formErrors.required').toString(),
                                   })}
                            />
                            <span className="control__content">2</span>
                        </label>

                        <label className="control" htmlFor="3">
                            <input type="checkbox"
                                   id="3"
                                   value="3"
                                   onClick={() => handleCheckboxClick(2)}
                                   checked={checkboxes[2]}
                                   {...register('rooms', {
                                       valueAsNumber: true,
                                       value: '3',
                                       required: t('formErrors.required').toString(),
                                   })}
                            />
                            <span className="control__content">3</span>
                        </label>

                        <label className="control" htmlFor="4">
                            <input type="checkbox"
                                   id="4"
                                   value="4,5,6,7"
                                   onClick={() => handleCheckboxClick(3)}
                                   checked={checkboxes[3]}
                                   {...register('rooms', {
                                       valueAsNumber: true,
                                       value: '4,5,6,7',
                                       required: t('formErrors.required').toString(),
                                   })}
                            />
                            <span className={`control__content ${apartmentBlockStyles.checkBox}`}>4+</span>
                        </label>
                    </div>
                </div>
            </div>

            <div className={apartmentBlockStyles.area_row}>
                <div className={isScreenResolutionMobile ? 'flex-column' : ''}>
                    <h5 className="mt-4 mb-3">{t('createNewOfferPage.apartment.area.title')}</h5>
                    <div>
                        <div className="flex_row_new_offer mb-3">
                            <div className="lbl-apartment">{t('createNewOfferPage.apartment.area.general')}</div>
                            <div>
                                <input
                                    placeholder=""
                                    type="number"
                                    id="totalArea"
                                    defaultValue={offerWithId ? offerWithId.totalArea : ''}

                                    className={
                                        errors.totalArea
                                            ? `${apartmentBlockStyles.error_input_area}`
                                            : `${apartmentBlockStyles.area}`
                                    }
                                    {...register('totalArea', {
                                        required: t('formErrors.required').toString(),
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength').toString(),
                                        },
                                    })}
                                />
                                {errors.totalArea ? (
                                    <p className="error-message">{errors.totalArea.message}</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </div>
                        <div className="flex_row_new_offer mb-3">
                            <div className="lbl-apartment">{t('createNewOfferPage.apartment.area.living')}</div>
                            <div>
                                <input
                                    placeholder=""
                                    type="number"
                                    defaultValue={offerWithId ? offerWithId.livingArea : ''}
                                    className={
                                        errors.livingArea
                                            ? `${apartmentBlockStyles.error_input_area}`
                                            : `${apartmentBlockStyles.area}`
                                    }
                                    {...register('livingArea', {
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength').toString(),
                                        },
                                    })}
                                />
                                {errors.livingArea ? (
                                    <p className="error-message">{errors.livingArea.message}</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </div>

                        <div className="flex_row_new_offer">
                            <div className="lbl-apartment">{t('createNewOfferPage.apartment.area.kitchen')}</div>
                            <div className="">
                                <input
                                    placeholder=""
                                    type="number"
                                    defaultValue={offerWithId ? offerWithId.kitchenArea : ''}
                                    className={
                                        errors.kitchenArea
                                            ? `${apartmentBlockStyles.error_input_area}`
                                            : `${apartmentBlockStyles.area}`
                                    }
                                    {...register('kitchenArea', {
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength').toString(),
                                        },
                                    })}
                                />
                                {errors.kitchenArea ? (
                                    <p className="error-message">
                                        {errors.kitchenArea.message}
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ApartmentBlock;