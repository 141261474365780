import {useEffect, useState} from 'react';

interface Props {
    targetElementId: string;
    visibilityOffset?: number;
    defaultBottom?: number;
}

export const useStickyStop = ({targetElementId, visibilityOffset = 150, defaultBottom = 20}: Props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [bottomOffset, setBottomOffset] = useState(defaultBottom);

    useEffect(() => {
        const handleScroll = () => {
            const targetElement = document.getElementById(targetElementId);
            if (targetElement) {
                const targetRect = targetElement.getBoundingClientRect();
                const windowHeight = window.innerHeight;

                if (targetRect.top < windowHeight) {
                    const overlap = windowHeight - targetRect.top;
                    setBottomOffset(overlap + defaultBottom);
                } else {
                    setBottomOffset(defaultBottom);
                }
            }

            setIsVisible(window.scrollY > visibilityOffset);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [targetElementId, visibilityOffset, defaultBottom]);

    return {isVisible, bottomOffset};
};