import React from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import useWindowSize from '../../hooks/useWindowSize';

import footerStyles from './Footer.module.scss';

const Footer: React.FC = () => {

    const {t} = useTranslation();

    const history = useHistory();

    const {isScreenResolutionMobile} = useWindowSize();

    return (
        <div className={footerStyles.page_wrapper} id={'footer'}>
            <div className={footerStyles.footer_container}>
                <div className={footerStyles.contact}>
                    <div className={footerStyles.contact_flex}>
                        <svg width="468" height="66" viewBox="0 0 468 66" fill="none"
                             xmlns="http://www.w3.org/2000/svg"
                             className={footerStyles.nav_link_size}>
                            <path
                                d="M326.278 65.6574V0.25H391.238V65.6574H326.278ZM343.616 47.8717H373.465V17.9764H343.616V47.8717Z"
                                fill="#34a790" />
                            <path
                                d="M306.497 30.8237C314.116 32.8913 318.89 37.0925 319.693 44.7046C320.477 52.1916 318.014 58.6315 311.291 62.2598C307.781 64.1562 303.402 64.6633 299.333 65.2361C295.343 65.7959 291.247 65.7629 287.197 65.7893C277.88 65.8551 268.562 65.7959 259.238 65.7893C258.171 65.7893 257.104 65.7893 255.82 65.7893V0.223883C256.617 0.151449 257.453 0.0131625 258.283 0.0131625C271.222 -7.23404e-06 284.155 0.0131697 297.094 0C300.861 0 304.555 0.375329 307.939 2.15324C313.55 5.09667 316.625 9.6929 316.691 16.1197C316.75 22.4214 314.03 27.044 308.315 29.8294C307.834 30.0664 307.373 30.343 306.497 30.8237ZM276.326 51.5331C281.119 51.5331 285.808 51.566 290.496 51.5133C291.8 51.5001 293.13 51.3487 294.388 51.0194C297.759 50.1305 299.478 47.8653 299.405 44.6321C299.333 41.5504 297.687 39.1074 294.223 38.8572C288.277 38.4358 282.278 38.7387 276.326 38.7387V51.5331ZM276.319 25.4241C281.686 25.4241 286.71 25.7138 291.681 25.3253C295.303 25.0422 296.837 22.7638 296.778 19.3068C296.719 16.1065 295.086 13.8545 291.635 13.6175C286.565 13.2685 281.455 13.5253 276.319 13.5253V25.4175V25.4241Z"
                                fill="#34a790" />
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M420.435 0.303223H397.118C397.118 0.303239 397.118 0.303256 397.118 0.303272H397.052C399.901 4.244 402.716 8.13867 405.516 12.0124C410.57 19.0577 415.565 26.0204 420.572 32.9938C415.565 39.9671 410.57 46.9298 405.516 53.9751C402.716 57.8488 399.901 61.7435 397.052 65.6842H397.118C397.118 65.6842 397.118 65.6843 397.118 65.6843H420.435C420.435 65.6843 420.435 65.6842 420.435 65.6842H420.692C424.557 60.3307 428.409 54.997 432.4 49.4789C434.084 51.8056 435.711 54.0537 437.291 56.2373C439.646 59.4921 441.898 62.6036 444.081 65.6184H467.721C459.998 54.9522 452.348 44.384 444.75 33.8887C444.534 33.5904 444.318 33.292 444.102 32.9938C444.317 32.6961 444.533 32.3984 444.749 32.1006C452.347 21.6047 459.998 11.0359 467.721 0.369104H444.081C441.897 3.38489 439.645 6.49745 437.289 9.75339C435.709 11.936 434.083 14.1831 432.4 16.5086C428.409 10.9905 424.557 5.65676 420.692 0.303272H420.435C420.435 0.303256 420.435 0.303239 420.435 0.303223Z"
                                  fill="#34a790" />
                            <path
                                d="M0 65.117V0H35.1874C40.6475 0 45.1302 1.04484 48.6355 3.13451C52.2082 5.15678 54.8371 7.95425 56.5223 11.5269C58.275 15.0996 59.1513 19.1778 59.1513 23.7616C59.1513 28.1432 58.039 32.0192 55.8145 35.3897C53.59 38.6927 50.7926 41.3216 47.4221 43.2765C48.7029 44.2876 49.7814 45.7706 50.6578 47.7255L58.2413 65.117H35.5919L28.9184 50.0511C28.4465 49.0399 27.941 48.3322 27.4017 47.9277C26.8624 47.5232 26.0198 47.321 24.8739 47.321H21.7394V65.117H0ZM21.7394 30.5362H29.7273C34.5807 30.5362 37.0075 28.278 37.0075 23.7616C37.0075 19.1778 34.7493 16.8859 30.2329 16.8859H21.7394V30.5362Z"
                                fill="#253040" />
                            <path
                                d="M65.6426 65.9998V0.381348H116.69V16.5822H87.5494V24.9373H112.92V41.5457H87.5494V49.7989H116.69V65.9998H65.6426Z"
                                fill="#253040" />
                            <path
                                d="M124.298 65.9998V0.381348H142.741L164.342 30.6433V0.381348H186.248V65.9998H167.602L146.205 35.8398V65.9998H124.298Z"
                                fill="#253040" />
                            <path
                                d="M209.936 65.9998V16.3784H192.411V0.381348H249.47V16.3784H231.843V65.9998H209.936Z"
                                fill="#253040" />
                        </svg>
                        {!isScreenResolutionMobile ?
                            <div className="flex-row gap-5">
                                <p className={footerStyles.contact_p_privacy}
                                   onClick={() => history.push('/privacy')}>{t('homePage.footer.privacy')}</p>
                                <p className={footerStyles.contact_p_privacy}
                                   onClick={() => history.push('/contact-us')}
                                >
                                    {t('homePage.contact')}
                                </p>
                                <p className={footerStyles.contact_p}>{t('homePage.footer.text')} <span
                                    className={footerStyles.contact_span}>{t('homePage.footer.contact')}</span></p>
                            </div>
                            :
                            <>
                                <p className={`${footerStyles.contact_p} mb-3`}
                                   onClick={() => history.push('/privacy')}>{t('homePage.footer.privacy')}</p>
                                <p className={footerStyles.contact_p}
                                   onClick={() => history.push('/contact-us')}
                                >
                                    {t('homePage.contact')}
                                </p>
                                <p className={footerStyles.contact_p}>{t('homePage.footer.text')} <a
                                    href="mailto:rentbox.acc@gmail.com"
                                    className={footerStyles.contact_span}>{t('homePage.footer.contact')}</a></p>
                            </>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;