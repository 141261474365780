import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Modal from '../../../common/Modal';
import AuthenticationWithPhone, {Label} from '../AuthenticationWithPhone/AuthenticationWithPhone';
import EnterCodeModal from '../EnterCodeModal/EnterCodeModal';
import authSelectors from '../../../features/auth/authSelectors';
import authSlice from '../../../features/auth/authSlice';
import useWindowSize from '../../../hooks/useWindowSize';
import {useTranslation} from 'react-i18next';
import useModalScroll from '../../../hooks/useModalScroll';

import close from '../../../assets/icons/close.svg';

import authModalStyles from './AuthModal.module.scss';
import modalStyles from '../../../styles/modalStyles.module.scss';
import './AuthModal.scss';

interface Props {
    isAuthModalOpen: boolean;
    toggleAuthModal: Dispatch<SetStateAction<boolean>>;
}

const AuthModal: React.FC<Props> = ({isAuthModalOpen, toggleAuthModal}) => {

    const dispatch = useDispatch();

    const ref = React.useRef<HTMLDivElement>(null);

    const {t} = useTranslation();

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);

        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    const enterCodeModal = useSelector(authSelectors.enterCodeModal);

    const {isScreenResolutionMobile} = useWindowSize();

    const headerByMobileVersion: string = useSelector(authSelectors.getHeaderByMobileVersion);

    const localizeHeader = (header: string) => {
        switch (header.toString()) {
            case Label.Login:
                return t('authModal.Login');
            case Label.Forgot:
                return t('authModal.Forgot');
            case Label.SignUp:
                return t('authModal.SignUp');
        }
    };

    const isScrollable = useModalScroll(isAuthModalOpen);

    return (
        <>
            <Modal
                width={isScreenResolutionMobile ? '100%' : 'auto'}
                height={isScreenResolutionMobile ? '100%' : 'auto'}
                isOpen={isAuthModalOpen}
                toggle={toggleAuthModal}
            >
                {isScreenResolutionMobile ?
                    <>
                        <h4 className={modalStyles.header}>
                            {localizeHeader(headerByMobileVersion)}
                        </h4>
                        <div
                            role="button"
                            onKeyDown={() => toggleAuthModal(!isAuthModalOpen)}
                            onClick={() => {
                                dispatch(authSlice.actions.toggleCodeModal(false));
                                toggleAuthModal(!isAuthModalOpen);
                            }}
                            className={modalStyles.close}
                        >
                            <p>
                                <img src={close} alt="close" />
                            </p>
                        </div>
                        <div className={modalStyles.line}></div>
                    </>
                    :
                    <div
                        role="button"
                        onKeyDown={() => toggleAuthModal(!isAuthModalOpen)}
                        onClick={() => {
                            dispatch(authSlice.actions.toggleCodeModal(false));
                            toggleAuthModal(!isAuthModalOpen);
                        }}
                        className={authModalStyles.close}
                    >
                        <p>
                            <img src={close} alt="close" />
                        </p>
                    </div>}
                <div className={isScrollable ? authModalStyles.scroll : authModalStyles.mt}>
                    {enterCodeModal ? (
                        <EnterCodeModal toggleModal={toggleAuthModal} isModalOpen={true} />
                    ) : (
                        <AuthenticationWithPhone
                            toggleModal={toggleAuthModal}
                            isModalOpen={isAuthModalOpen}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
};

export default AuthModal;