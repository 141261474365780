import {useState, useEffect, useCallback} from 'react';

function useModalScroll(isModalOpen: boolean) {

    const [isScrollable, setIsScrollable] = useState(false);

    const checkScrollable = useCallback(() => {
        const modalElement = document.querySelector('.modal-window') as HTMLElement | null;
        if (modalElement) {
            const modalHeight = modalElement.offsetHeight + 150;
            const windowHeight = window.innerHeight;
            setIsScrollable(modalHeight > windowHeight);
        }
    }, []);

    useEffect(() => {
        if (isModalOpen) {
            checkScrollable();

            window.addEventListener('resize', checkScrollable);

            const modalElement = document.querySelector('.modal-window');
            if (modalElement) {
                const observer = new MutationObserver(checkScrollable);
                observer.observe(modalElement, {childList: true, subtree: true});
                return () => observer.disconnect();
            }
        }

        return () => {
            window.removeEventListener('resize', checkScrollable);
        };
    }, [isModalOpen, checkScrollable]);

    return isScrollable;
}

export default useModalScroll;