import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Modal from '../../common/Modal';
import acceptanceOperations from '../../features/acceptance/acceptanceOperations';
import {
    CreateAcceptanceSuccessActionPayloadType,
    OfferElementsType,
    ResponseRefreshUserSuccessActionPayloadType,
} from '../../models/generalTypes';
import authSelectors from '../../features/auth/authSelectors';
import AuthModal from '../Authentification/AuthModal/AuthModal';
import paymentSelectors from '../../features/payment/paymentSelectors';
// import PriceModal from '../Payment/NavigationBalanceBlock/modal/PriceModal';
import LoaderButtonFormService from '../../common/Button/loaderButtonFormService/LoaderButtonFormService';
import useWindowSize from '../../hooks/useWindowSize';
import userSelectors from '../../features/user/userSelectors';
import BasicModal from '../BasicModal/BasicModal';
import useModalScroll from '../../hooks/useModalScroll';

import LoaderColor from '../../common/Button/enum/LoaderColor';

import closeButton from '../../assets/icons/close.svg';
import paw from '../../../src/components/Acceptances/images/pet.svg';
import security from '../../../src/components/Acceptances/images/security.svg';

import createAcceptanceStyles from './CreateAcceptance.module.scss';
import modalStyles from '../../styles/modalStyles.module.scss';
import 'react-datepicker/dist/react-datepicker.css';

type PropTypes = {
    offerId?: string;
    offer: OfferElementsType;
};

const CreateAcceptance: React.FC<PropTypes> = ({offerId, offer}) => {

    const [isModalOpen, toggleModal] = useState(false);

    const myCurrentDate = new Date();
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const [startDate, setStartDate] = useState(tomorrow);

    // const [isPriceModalOpen, togglePriceModal] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm();
    const history = useHistory();

    const oneMonth = new Date(myCurrentDate);
    oneMonth.setMonth(oneMonth.getMonth() + 1);

    const threeMonth = new Date(myCurrentDate);
    threeMonth.setMonth(threeMonth.getMonth() + 3);

    const sixMonth = new Date(myCurrentDate);
    sixMonth.setMonth(sixMonth.getMonth() + 1);

    const oneYear = new Date(myCurrentDate);
    oneYear.setMonth(oneYear.getMonth() + 12);

    const {t} = useTranslation();

    const dispatch = useDispatch();

    type PricesType = {
        serviceName: string,
        price: string | number
    }

    const prices: Array<PricesType> = useSelector(paymentSelectors.getPrices);

    const createAcceptancePrice = prices.filter(item => item.serviceName === 'createAcceptance').map(item => item.price)[0];

    const balance = useSelector(paymentSelectors.getBalance);

    const submit = async (values: any) => {

        const todaysDate = new Date();

        const afterYear = todaysDate.getUTCFullYear() + 1;

        todaysDate.setFullYear(afterYear);

        const isoDayAfterYear = todaysDate.toISOString();

        const petDeposit = offer.petDeposit !== undefined ? offer.petDeposit.toString() : '';
        const securityDeposit = offer.securityDeposit !== undefined ? offer.securityDeposit.toString() : '';

        const updValues = {
            ...values,
            petDeposit,
            securityDeposit,
            moveInDate: startDate,
            expiresOn: isoDayAfterYear,
            status: 'WAITING',
        };

        const cred = {
            id: offerId as string,
            credentials: updValues as CreateAcceptanceSuccessActionPayloadType,
        };

        offerId !== undefined &&
        await dispatch(acceptanceOperations.createAcceptance(cred));

        await toggleModal(false);
    };

    const isAuth = useSelector(authSelectors.getIsAuthenticated);

    const [isAuthModalOpen, toggleAuthModal] = useState(false);

    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const user = useSelector(
        userSelectors.getUserProfile,
    ) as ResponseRefreshUserSuccessActionPayloadType;

    const routeChange = async () => {
        // if (balance < createAcceptancePrice) {
        //     await togglePriceModal(!isPriceModalOpen);
        if (!isAuth) {
            toggleAuthModal(!isAuthModalOpen);
        } else if (isAuth) {
            toggleModal(!isModalOpen);
        } else if (!user.emailConfirmed || !user.phoneConfirmed) {
            await setIsConfirmationModalOpen(!isConfirmationModalOpen);
        }
    };

    const [characterCount, setCharacterCount] = useState(0);

    const handleCharacterChange = (e: any) => {
        const inputText = e.target.value;
        setCharacterCount(inputText.length);
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const confirm = () => {
        setIsConfirmationModalOpen(false);
        history.push('/account/profile');
    };

    const isScrollable = useModalScroll(isModalOpen);

    return (
        <div>
            {/*{isPriceModalOpen &&*/}
            {/*    <PriceModal isModalOpen={isPriceModalOpen} toggleModal={togglePriceModal} lackBalance={true}/>}*/}
            <BasicModal isModalOpen={isConfirmationModalOpen}
                        toggleModal={setIsConfirmationModalOpen}
                        header={t('confirmationModal.header')}
                        question={t('confirmationModal.question')}
                        leftButton={t('confirmationModal.btn.cancel')}
                        rightButton={t('confirmationModal.btn.confirm')}
                        onClick={confirm}
            />
            <div className={createAcceptanceStyles.acs}>
                {history.location.pathname !== '/offers' ? (
                    <button
                        className={createAcceptanceStyles.btn_main}
                        onClick={routeChange}
                    >
                        {t('acceptance.CreateAcceptance')}
                    </button>
                ) : (
                    <button
                        className={createAcceptanceStyles.btn_offers}
                        onClick={routeChange}
                    >
                        {t('acceptance.CreateAcceptance')}
                    </button>
                )}
            </div>

            <AuthModal isAuthModalOpen={isAuthModalOpen} toggleAuthModal={toggleAuthModal} />

            <Modal
                width={isScreenResolutionMobile ? '100%' : 'auto'}
                height={isScreenResolutionMobile ? '100%' : 'auto'}
                isOpen={isModalOpen}
                toggle={toggleModal}
            >
                <div
                    role="button"
                    onKeyDown={() => toggleModal(!isModalOpen)}
                    onClick={() => {
                        toggleModal(!isModalOpen);
                    }}
                    className={modalStyles.close}
                >
                    <p>
                        <img src={closeButton} alt="close" />
                    </p>
                </div>

                <form onSubmit={handleSubmit(submit)} className={createAcceptanceStyles.form}>
                    <h4 className={modalStyles.header}>{t('acceptance.CreateAcceptance')}</h4>
                    <div className={modalStyles.line} />
                    <div className={isScrollable ? createAcceptanceStyles.scrollBox : createAcceptanceStyles.without_scroll}>
                        <h4 className={createAcceptanceStyles.subtitle}>
                            {t('acceptance.SetPrice')}
                        </h4>
                        <div>
                            <div className={createAcceptanceStyles.price_currency}>
                                <input
                                    defaultValue={offer.price}
                                    type="number"
                                    className={
                                        errors.price
                                            ? `${createAcceptanceStyles.error}`
                                            : `${createAcceptanceStyles.input_block}`
                                    }
                                    {...register('price', {
                                        required: t('formErrors.required').toString(),
                                        min: {
                                            value: 0,
                                            message: t('formErrors.minLength'),
                                        },
                                    })}
                                />
                                <input
                                    className={`${createAcceptanceStyles.input_cur} ${createAcceptanceStyles.form_control_price} ${createAcceptanceStyles.currency}`}
                                    value={offer.currency}
                                    {...register('currency')}
                                    readOnly={true}
                                />
                            </div>
                            <div>
                                {errors.price ? (
                                    <p className={createAcceptanceStyles.error_message}>{errors.price.message}</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                        </div>

                        {offer.petDeposit !== null &&
                          <div>
                            <div className={createAcceptanceStyles.label}>{t('acceptance.PetDeposit')}</div>
                            <div className={createAcceptanceStyles.price_currency}>
                              <input
                                defaultValue={offer.petDeposit}
                                disabled={true}
                                type="number"
                                className={
                                    errors.petDeposit
                                        ? `${createAcceptanceStyles.error}`
                                        : `${createAcceptanceStyles.deposit}`
                                }
                                {...register('petDeposit', {
                                    required: false,
                                })}
                              />
                              <span
                                className={`${createAcceptanceStyles.input_cur} ${createAcceptanceStyles.form_control}`}>
                                    <img className={createAcceptanceStyles.img_input} src={paw} alt="paw" />
                                </span>
                            </div>
                            <div>
                                {errors.petDeposit ? (
                                    <p className={createAcceptanceStyles.error_message}>{errors.petDeposit.message}</p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                          </div>}

                        {offer.securityDeposit !== null &&
                          <div>
                            <div className={createAcceptanceStyles.label}>{t('acceptance.SecurityDeposit')}</div>
                            <div className={createAcceptanceStyles.price_currency}>
                              <input
                                type="number"
                                defaultValue={offer.securityDeposit}
                                disabled={true}
                                className={
                                    errors.securityDeposit
                                        ? `${createAcceptanceStyles.error}`
                                        : `${createAcceptanceStyles.deposit}`
                                }
                                {...register('securityDeposit', {
                                    required: false,
                                })}
                              />
                              <span
                                className={createAcceptanceStyles.input_cur}>
                                    <img className={createAcceptanceStyles.img_input} src={security} alt="security" />
                                </span>
                            </div>
                            <div>
                                {errors.securityDeposit ? (
                                    <p className={createAcceptanceStyles.error_message}>
                                        {errors.securityDeposit.message}
                                    </p>
                                ) : (
                                    <p></p>
                                )}
                            </div>
                          </div>}

                        <hr className={createAcceptanceStyles.line} />

                        <div>
                            <h4 className={createAcceptanceStyles.subtitle}>{t('acceptance.Message')}</h4>
                            <p className={createAcceptanceStyles.character}>{characterCount}/300</p>
                            <textarea
                                defaultValue=""
                                maxLength={300}
                                className={
                                    errors.comment
                                        ? `  ${createAcceptanceStyles.error_input} ${createAcceptanceStyles.address} `
                                        : `    ${createAcceptanceStyles.address}`
                                }
                                {...register('comment', {
                                    required: false,
                                    onChange: handleCharacterChange,
                                })}
                            />
                            {errors.title ? (
                                <p className={createAcceptanceStyles.error_message}>{errors.title.message}</p>
                            ) : (
                                <p></p>
                            )}
                        </div>
                    </div>

                    <div className={createAcceptanceStyles.down_line} />

                    <div className={`${createAcceptanceStyles.bottom_block} ${createAcceptanceStyles.flex_row}`}>
                        <LoaderButtonFormService buttonContent={t('acceptance.Confirm')}
                                                 buttonClassName={'create_acceptance_btn'} submit={handleSubmit(submit)}
                                                 color={LoaderColor.White} />
                    </div>
                </form>
            </Modal>
        </div>
    );
};

export default CreateAcceptance;