import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";

import one from "../../../assets/icons/homePage/step-one.gif";
import two from "../../../assets/icons/homePage/step-two.gif";
import three from "../../../assets/icons/homePage/step-three.gif";
import four from "../../../assets/icons/homePage/step-four.gif";

import scrollGifsStyles from "./ScrollGit.module.scss";
// eslint-disable-next-line import/no-extraneous-dependencies
import ReactPlayer from 'react-player';

import firstVideo from "../../../assets/icons/homePage/video/firstVideo.mp4"
import secondVideo from "../../../assets/icons/homePage/video/secondVideo.mp4"
import thirdVideo from "../../../assets/icons/homePage/video/thirdVideo.mp4"

type Props = {
    isRent: boolean
}

const ScrollGif: React.FC<Props> = ({isRent}) => {

    const stepsRef = useRef<HTMLDivElement[]>([]);

    const {t} = useTranslation();

    useEffect(() => {
        const handleScroll = () => {
            stepsRef.current.forEach((step) => {
                const distanceFromTop = step.getBoundingClientRect().top;
                const translateY = -distanceFromTop * 0.1;
                step.style.transform = `translateY(${translateY}px)`;
                if (isElementInViewport(step)) {
                    step.classList.add(scrollGifsStyles.visible);
                } else {
                    step.classList.remove(scrollGifsStyles.visible);
                }
            });
        };

        const isElementInViewport = (el: HTMLElement) => {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        };

        window.addEventListener('scroll', handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const videoStyle = {
        width: '640px',
        height: '500px',
        objectFit: 'cover' as const,
        position: 'relative' as const,
        borderRadius: '16px',
        opacity: 0,
        willChange: 'transform',
        transformOrigin: 'center bottom',
        transition: 'opacity 1s ease, transform 1s ease',
        overflow: 'hidden' as const,
    };

    return (
        <div>
            <div className={scrollGifsStyles.steps_flex}>
                <div className={scrollGifsStyles.steps_div}>
                    <div className={scrollGifsStyles.steps_img} ref={(el) => stepsRef.current[0] = el!}>
                        <ReactPlayer url={firstVideo} loop playing muted width="100%" height="100%"/>
                    </div>
                    {/*<img src={one} alt="one" className={scrollGifsStyles.steps_img}*/}
                    {/*     ref={(el) => stepsRef.current[0] = el!} />*/}
                </div>
                <div className={scrollGifsStyles.steps_text}>
                    <p className={scrollGifsStyles.steps_text_p_step}>{t("homePage.steps.one.name")}</p>
                    <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.one.title")}</h2>
                    <div className={scrollGifsStyles.steps_line}></div>
                    <p className={scrollGifsStyles.steps_text_p}>
                        {t("homePage.steps.one.text.first")}
                        <br/>{t("homePage.steps.one.text.second")}
                        <br/>{t("homePage.steps.one.text.third")}
                        <br/>{t("homePage.steps.one.text.fourth")}
                    </p>
                </div>
            </div>

            <div className={scrollGifsStyles.steps_flex}>
                <div className={scrollGifsStyles.steps_div}>
                    <div className={scrollGifsStyles.steps_img} ref={(el) => stepsRef.current[1] = el!}>
                        <ReactPlayer url={secondVideo} loop playing muted width="100%" height="100%"/>
                    </div>
                    {/*<img src={two} alt="two" className={scrollGifsStyles.steps_img}*/}
                    {/*     ref={(el) => stepsRef.current[1] = el!}/>*/}
                </div>
                <div className={scrollGifsStyles.steps_text}>
                    <p className={scrollGifsStyles.steps_text_p_step}>{t("homePage.steps.two.name")}</p>
                    {isRent ?
                        <>
                            <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.two.renting.title")}</h2>
                            <div className={scrollGifsStyles.steps_line}></div>
                            <p className={scrollGifsStyles.steps_text_p}>
                                {t("homePage.steps.two.renting.text.first")}
                                <br/>{t("homePage.steps.two.renting.text.second")}
                                <br/>{t("homePage.steps.two.renting.text.third")}
                                <br/>{t("homePage.steps.two.renting.text.fourth")}
                            </p>
                        </>
                        :
                        <>
                            <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.two.looking.title")}</h2>
                            <div className={scrollGifsStyles.steps_line}></div>
                            <p className={scrollGifsStyles.steps_text_p}>
                                {t("homePage.steps.two.looking.text.first")}
                                <br/>{t("homePage.steps.two.looking.text.second")}
                                <br/>{t("homePage.steps.two.looking.text.third")}
                            </p>
                        </>}
                </div>
            </div>

            <div className={scrollGifsStyles.steps_flex}>
                <div className={scrollGifsStyles.steps_div}>
                    {/*<img src={three} alt="three" className={scrollGifsStyles.steps_img}*/}
                    {/*     ref={(el) => stepsRef.current[2] = el!}/>*/}
                    <div className={scrollGifsStyles.steps_img} ref={(el) => stepsRef.current[2] = el!}>
                        <ReactPlayer url={thirdVideo} loop playing muted width="100%" height="100%"/>
                    </div>
                </div>
                <div className={scrollGifsStyles.steps_text}>
                    <p className={scrollGifsStyles.steps_text_p_step}>{t("homePage.steps.three.name")}</p>
                    {isRent ?
                        <>
                            <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.three.renting.title")}</h2>
                            <div className={scrollGifsStyles.steps_line}></div>
                            <p className={scrollGifsStyles.steps_text_p}>
                                {t("homePage.steps.three.renting.text.first")}
                                <br/>{t("homePage.steps.three.renting.text.second")}
                            </p>
                        </>
                        :
                        <>
                            <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.three.looking.title")}</h2>
                            <div className={scrollGifsStyles.steps_line}></div>
                            <p className={scrollGifsStyles.steps_text_p}>
                                {t("homePage.steps.three.looking.text.first")}
                                <br/>{t("homePage.steps.three.looking.text.second")}
                                <br/>{t("homePage.steps.three.looking.text.third")}
                            </p>
                        </>}
                </div>
            </div>

            {/*<div className={scrollGifsStyles.steps_flex}>*/}
            {/*    <div className={scrollGifsStyles.steps_div}>*/}
            {/*        <img src={four} alt="four" className={scrollGifsStyles.steps_img}*/}
            {/*             ref={(el) => stepsRef.current[3] = el!}/>*/}
            {/*    </div>*/}
            {/*    <div className={scrollGifsStyles.steps_text}>*/}
            {/*        <p className={scrollGifsStyles.steps_text_p_step}>{t("homePage.steps.four.name")}</p>*/}
            {/*        {isRent ?*/}
            {/*            <>*/}
            {/*                <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.four.renting.title")}</h2>*/}
            {/*                <div className={scrollGifsStyles.steps_line}></div>*/}
            {/*                <p className={scrollGifsStyles.steps_text_p}>*/}
            {/*                    {t("homePage.steps.four.renting.text.first")}*/}
            {/*                </p>*/}
            {/*            </>*/}
            {/*            :*/}
            {/*            <>*/}
            {/*                <h2 className={scrollGifsStyles.steps_text_h}>{t("homePage.steps.four.looking.title")}</h2>*/}
            {/*                <div className={scrollGifsStyles.steps_line}></div>*/}
            {/*                <p className={scrollGifsStyles.steps_text_p}>*/}
            {/*                    {t("homePage.steps.four.looking.text.first")}*/}
            {/*                </p>*/}
            {/*            </>}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    );
};

export default ScrollGif;
