import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    FilterType, SubscriptionsFilterType,
} from '../../../models/generalTypes';
import filterSlice from '../../../features/filter/filterSlice';
import filterSelectors from '../../../features/filter/filterSelectors';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import ControlInputNumber from '../../../common/ControlInput/ControlInputNumber';
import {Type} from '../../../pages/SearchPage/SearchPage';

import './DropDownCurrencyPrice.scss';

type DropDownCurrencyPricePropsType = {
    width?: string | number;
    borderRadius?: string;
    children?: React.ReactNode;
};

const DropDownCurrencyPrice: React.FC<DropDownCurrencyPricePropsType> = ({width, borderRadius, children}) => {

    const {t} = useTranslation();

    const ref = React.useRef<HTMLDivElement>(null);

    const dispatch = useDispatch();

    const history = useHistory();

    const filter = useSelector(filterSelectors.getFilter) as FilterType;

    const subscriptionFilter = useSelector(filterSelectors.getSubscriptionFilter) as SubscriptionsFilterType;

    const {
        handleSubmit,
        formState: {errors},
        reset,
        control,
    } = useForm({
        defaultValues: filter,
    });

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const initialHeader = t('filterModal.price');

    const [header, setHeader] = useState('');

    const submit = (values: any) => {
        if (type === Type.Looking) {
            dispatch(filterSlice.actions.setPrice(values.priceTo.replace(/\s/g, '')));
        } else if (type === Type.Rent) {
            const priceFrom = values.priceFrom.replace(/\s/g, '');
            const priceTo = values.priceTo.replace(/\s/g, '');

            dispatch(filterSlice.actions.setPriceFrom(values.priceFrom ? priceFrom : ''));
            dispatch(filterSlice.actions.setPriceTo(values.priceTo ? priceTo : ''));
        }
        setIsMenuOpen(false);
    };

    useEffect(() => {
        reset(filter);
    }, [filter]);

    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('mousedown', checkIfClickedOutside);
        return () => {
            document.removeEventListener('mousedown', checkIfClickedOutside);
        };
    }, [isMenuOpen]);

    useEffect(() => {
        const from = filter.priceFrom;
        const to = filter.priceTo;

        if (from !== '' && to !== '') {
            setHeader(from + ' - ' + to);
        } else if (from !== '' && to === '') {
            setHeader(`${t('filterModal.from')} ` + from);
        } else if (filter.priceTo !== '' && from === '') {
            setHeader(`${t('filterModal.to')} ` + to);
        } else setHeader('');
    }, [filter, t]);

    const {isScreenResolutionMobile} = useWindowSize();

    const type = useSelector(filterSelectors.getType);

    return (
        <>
            <div ref={ref} className="parent_div_price">
                <div
                    role="button"
                    tabIndex={0}
                    style={{width, borderRadius}}
                    className={history.location.pathname === '/offers' || history.location.pathname === '/subscriptions' ?
                        'selector-price_offers selector-price__bg' :
                        'selector-price'
                    }
                    onKeyDown={() => setIsMenuOpen(oldState => !oldState)}
                    onClick={() => setIsMenuOpen(oldState => !oldState)}
                >
                    <span className="dropdown-label">
                      <div className="flex-row">
                          <span className="initial_header">
                              {type === Type.Rent ? `${initialHeader} ` : t('filterModal.rentalPrice')}
                          </span>
                          {!isScreenResolutionMobile &&
                            <span className="header_price">
                                {type !== Type.Looking ? header : subscriptionFilter.price}
                              </span>}
                      </div>
                    </span>
                    <div>
                        <svg
                            className="icon_down_price"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M17 10L12 16L7 10H17Z" fill="#253040" />
                        </svg>
                    </div>
                </div>

                {isMenuOpen ? (
                    <form
                        className={history.location.pathname === '/offers' ? 'dropdown-container-price-offers' : 'dropdown-container-price'}
                        onSubmit={handleSubmit(submit)} id="formPrice">
                        <div className="group-flex">

                            <div className="flex-col">
                                <div className="control_div">
                                    <input
                                        className="currency"
                                        type="radio"
                                        name="currency"
                                        id="UAH"
                                        checked
                                    />
                                    <label className="control" htmlFor="UAH">
                                        <span className="control__currency">UAH</span>
                                    </label>
                                </div>
                                <div className="flex_price">
                                    {type === Type.Rent &&
                                      <label className="label_price" htmlFor="price-from">
                                        <ControlInputNumber fieldName={'priceFrom'}
                                                            inputClassName={errors.priceFrom
                                                                ? 'error-price input-price'
                                                                : 'input-price'}
                                                            control={control}
                                                            placeHolder={t('filterModal.from')}
                                        />
                                      </label>}
                                    <label className="label_price" htmlFor="price-to">
                                        <ControlInputNumber fieldName={'priceTo'}
                                                            inputClassName={errors.priceTo
                                                                ? 'error-price input-price'
                                                                : 'input-price'}
                                                            control={control}
                                                            placeHolder={type === Type.Rent ? t('filterModal.to') : t('filterModal.rentalPrice')}
                                        />
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="submit_btn"
                        >
                            {t('filterModal.apply')}
                        </button>
                    </form>
                ) : null}
            </div>
            {children}
        </>
    );
};

export default DropDownCurrencyPrice;