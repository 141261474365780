import React from 'react';
import {useStickyStop} from '../../hooks/useStickyStop';

import icon from './Icon.svg';

import styles from './ScrollToTopButton.module.scss';

const ScrollToTopButton: React.FC = () => {
    const { isVisible, bottomOffset } = useStickyStop({
        targetElementId: 'footer',
        visibilityOffset: 150,
        defaultBottom: 20,
    });

    const scrollToTop = () => {
        window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
    };

    return (
        <button
            type="button"
            className={styles.scrollToTop}
            style={{bottom: `${bottomOffset}px`, display: `${!isVisible ? 'none' : ''}`}}
            onClick={scrollToTop}
        >
            <img src={icon} alt="scroll to top" />
        </button>
    );
};

export default ScrollToTopButton;