import React, {Dispatch, SetStateAction} from 'react';
import {useSelector} from 'react-redux';
import geolocationSelectors from '../../../features/geolocation/geolocationSelectors';
import Select, {components} from 'react-select';
import {useTranslation} from 'react-i18next';
import useWindowSize from '../../../hooks/useWindowSize';

import caretDown from '../../../assets/icons/caret-down.svg';

interface Props {
    city: {code: string, name: string | undefined};
    setCity: Dispatch<SetStateAction<{code: string; name: string | undefined;}>>;
}

const SelectCityDropDown: React.FC<Props> = ({city, setCity}) => {

    const {t} = useTranslation();

    const fetchedCities = useSelector(
        geolocationSelectors.getFetchedCities,
    ) as Array<any>;

    const handleChangeCity = (e: any) => {
        setCity({code: e.latitude, name: e.name});
    };

    const {isScreenResolutionMobile} = useWindowSize();

    const getSelectWidth = () => {
        return isScreenResolutionMobile ? '100%' : 250;
    };

    const getControlStyles = (baseStyles: any) => {
        if (isScreenResolutionMobile) {
            return {
                ...baseStyles,
                height: '60px',
                borderRadius: '100px',
                backgroundColor: 'none',
                width: getSelectWidth(),
                border: 'none',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                },
                '&:active': {
                    border: 'none',
                    outline: 'none',
                },
                '&:focus': {
                    border: 'none',
                    outline: 'none',
                },
            };
        } else
            return {
                ...baseStyles,
                height: '60px',
                borderRadius: '100px 0 0 100px',
                backgroundColor: 'none',
                width: getSelectWidth(),
                paddingLeft: 20,
                paddingRight: 20,
                border: 'none',
                boxShadow: 'none',
                '&:hover': {
                    backgroundColor: '#e0e0e0',
                    border: 'none',
                },
                '&:active': {
                    border: 'none',
                    outline: 'none',
                },
                '&:focus': {
                    border: 'none',
                    outline: 'none',
                },
            };
    };

    const getContainerStyles = (provided: any) => {
        if (isScreenResolutionMobile) {
            return {
                ...provided,
                width: '100%',
                paddingLeft: '20px',
                paddingRight: '20px',
            };
        }
    };

    const customStyles = {
        option: (provided: any, state: any) => ({
            ...provided,
            color: '#253040',
            outline: 'none',
            border: 'none',
            backgroundColor: state.isFocused ? '#e0e0e0' : '#fff',
            boxShadow: 'none',
            padding: '5px 5px 5px 15px',
            marginLeft: '0',
            '&:hover': {
                backgroundColor: '#e0e0e0',
            },
            fontSize: '18px',
        }),
        control: (baseStyles: any, state: any) => (getControlStyles(baseStyles)),
        indicatorSeparator: (provided: any) => ({
            ...provided,
            display: 'none',
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: 0,
        }),
        dropdownIndicator: (provided: any) => ({
            ...provided,
            marginBottom: '5px',
            paddingRight: 0,
        }),
        placeholder: (provided: any) => ({
            ...provided,
            backgroundColor: '#e0e0e0',
            fontSize: '14px',
            fontWeight: 500,
        }),
        container: (provided: any) => (getContainerStyles(provided)),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '14px',
            overflow: 'hidden',
            boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.5)',
        }),
        menuList: (provided: any) => ({
            ...provided,
            padding: '0',
            margin: '0',
        }),
    };

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <img src={caretDown} alt="caretDown" />
            </components.DropdownIndicator>
        );
    };

    return (
        <>
            <Select
                menuPosition="fixed"
                components={{DropdownIndicator}}
                placeholder={t('createNewOfferPage.address.city.title').toUpperCase()}
                onChange={handleChangeCity}
                name="city"
                value={city}
                id={city.code}
                getOptionLabel={option => {
                    return t(`city.${option.name}`);
                }}
                getOptionValue={option => {
                    return option.code;
                }}
                options={fetchedCities}
                styles={customStyles}
                maxMenuHeight={150}
            />
        </>
    );
};

export default SelectCityDropDown;